import React from 'react';
import {Link} from 'gatsby';
import {Helmet} from 'react-helmet';
import virtualTours from '../data/virtual-tours';



export default ({title,children}) => (
    <div>
        <Helmet>
            <title>{title ? `${title} - ` : ''}Kahler Homes of Midland</title>
            <script src="https://ajax.aspnetcdn.com/ajax/jquery/jquery-2.1.4.min.js"></script>
            <script src="https://ajax.aspnetcdn.com/ajax/bootstrap/3.3.5/bootstrap.min.js"></script>
        </Helmet>
        <nav className="navbar navbar-default navbar-fixed-top affix">
            <div className="container">
                <div className="navbar-header">
                    <a href="/" className="pull-left navbar-brand navbar-logo"></a>

                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#navbar-collapse" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                </div>

                <div id="navbar-collapse" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/forsale/">For Sale</Link></li>
                        {virtualTours.length > 0 && 
                            <li>
                                <a href="#" className="dropdown-toggle" type="button" id="virtual-tours" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="true">
                                    Virtual Tours
                                    <span className="caret"></span>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="virtual-tours">
                                    {virtualTours.map((t,ii) => <li key={ii}><a href={t.url} target="_blank">{t.text}</a></li>)}
                                </ul>
                            </li>
                        }
                        <li><a href="/parade/">Parade of Homes</a></li>
                        <li><Link to="/about/">About</Link></li>
                        <li><Link to="/contact/">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
        {children}
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            Copyright ©{(new Date()).getFullYear()} &nbsp;
                            <a href="/">
                                Kahler Homes of Midland
                            </a>
                        </p>
                        <p>Call us: (432) 686-0734</p>
                        <p>Visit us: 4810 W Loop 250N, Bldg. B, Midland, TX 79707</p>
                    </div>
                </div>
            </div>
        </footer>
        
        <script src="./src/assets/lib/prettyphoto/js/jquery.prettyPhoto.js"></script>
        <script>
            //$("dt.gallery-icon a").prettyPhoto();
        </script>
    </div>
);