export default [
    {url: 'https://my.matterport.com/show/?m=62ni7kCyiJo', text: '6305 Woodrose'},
    {url: 'https://westtexas360.com/3d-model/5829-costa-mesa/skinned/', text: '5829 Costa Mesa'},
    {url: 'https://westtexas360.com/3d-model/fairfieldlanemidtx/skinned/', text: '2901 Fairfield Lane'},
    {url: 'https://westtexas360.com/3d-model/kahler-homes-presents-6104-barton-springs-ct/skinned/', text: '6104 Barton Springs'},
    {url: 'https://my.matterport.com/show/?m=5M9KPfNcxwL', text: '1206 Desert Rose'},
    {url: 'https://westtexas360.com/3d-model/kahler-homes-presents-1208-desert-rose-midland-tx/skinned/', text: '1208 Desert Rose'},
    {url: 'https://westtexas360.com/3d-model/7102-e-county-road-113-midland-tx/skinned/', text: '7102 E COUNTY ROAD 113'},
    {url: 'https://westtexas360.com/3d-model/kahler-homes-presents-corona-court-midland-tx/skinned/', text: '4505 Corona Ct'},
    {url: 'https://westtexas360.com/3d-model/kahler-homes-presents-4403-corona-court/skinned/', text: '4403 Corona Ct'}
];





